import { CSSProperties } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const defaultStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
}
const fullscreenStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 2
}
interface LoadingType {
    type?: string, // fullscreen: 全屏覆盖
    customStyle?: object, // 自定义样式
}
let loadingStyle = {};
const Loading = (prop: LoadingType) => {
    if (prop.type === 'fullscreen') {
        loadingStyle = {...defaultStyle, ...fullscreenStyle};
    } else {
        loadingStyle = defaultStyle;
    }
    if (prop.customStyle) {
        loadingStyle = {...defaultStyle, ...prop.customStyle}
    }
    return (
        <Spin indicator={antIcon} style={loadingStyle}/>
    )
}
export default Loading
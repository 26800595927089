// import { reduxStoreTypes } from '@/type/redux-store';

// export const GET_THEMES: reduxStoreTypes.ThemeType = 'GET_THEME';
// export const getTheme = (params: Omit<reduxStoreTypes.ThemeAction, 'type'>) => ({
//     type: GET_THEMES,
//     ...params
// })

// export const SET_THEMES: reduxStoreTypes.ThemeType = 'SET_THEME';
export const setLocale = (params: any) => ({
    type: 'setLocale',
    ...params
})
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.less';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import Router from './routers';
import store from './store';
import reportWebVitals from 'reportWebVitals';
import { register } from 'serviceWorker'
import { SystemContext } from './store/context';
import { getSystem } from './utils/common';
import { getLanguage, setLanguage } from '@/utils/common';
import  '@/lang/lang';
import { useEffect, useState } from 'react';
import eventBus from '@utils/events';
import { errorCatch } from '@utils/errorCatch';
errorCatch()
const IndexComponent = () => {
    const [intl, setIntl]  = useState(getLanguage())
    useEffect(() => {

        eventBus.on('changeLanguage',(language:any)=>{
            setIntl(language)
            setLanguage(language)
        });//接收事件

        const componentWillUnmount=()=>{
            eventBus.removeListener('changeLanguage', ()=>{
       
            });//移出事件总线
        }

        return componentWillUnmount
    }, [])

    

    return (
        <Provider store={store}>
            <SystemContext.Provider value={getSystem()}>
            <ConfigProvider locale={intl === 'ZH' ? zhCN : enUS}>
                <Router />
            </ConfigProvider>
            </SystemContext.Provider>
        </Provider>
    )
}

ReactDOM.render(
    <IndexComponent/>,
    document.getElementById('rootContainer')   
);
const m: any = module;
if (m.hot) {
    m.hot.accept();
}
reportWebVitals(console.log)
register()
import { combineReducers } from 'redux'
import tabs from './tabs'
import buttons from './buttons'
import collapsed from './collapsed'
import locale from './locale'
const rootReducer = combineReducers({
    tabs,
    buttons,
    collapsed,
    locale,
})
export default rootReducer

import { getLanguage } from "@/utils/common";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reduces";
const initialState = {
  locale: getLanguage(),
};
const configureStore = (preloadedState: any) => {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(thunkMiddleware)
  );
};
const store = configureStore(initialState);
export default store;

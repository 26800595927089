import {ADD_TAB, DELETE_TAB} from '../actions';
import { routes } from '@routers/index';
import { reduxStoreTypes } from '@type/redux-store';

const addTabs = function(state: RouteConfig[] = [], action: reduxStoreTypes.TabAction) {
    const {pathname}: any = action;
    const tab = routes.find((route: RouteConfig) => { // 路由配置中找到对应的路由配置
        return ((route.key && pathname.startsWith(route.key)) || route.path === pathname)
    });
    const isExistTabs = state.find((item: RouteConfig) => { // tabs是否已经存在该路径
        return ((item.key && pathname.startsWith(item.key)) || item.path === pathname)
    })
    // tabs是否已经存在该路径参数"/:"后面的部分
    const isExistParams = state.find(item => {
        return item.key && pathname.startsWith(item.key)
    })
    if (!isExistTabs && !!tab) { // 新增tabs
        return state.concat({...tab, path: pathname})
    } else if (!!isExistParams && !!tab) { // 路由参数不一样的路径，1:编辑tabs  2:要删除缓存
        return state.map((item: RouteConfig) => {
            if (item.key && pathname.startsWith(item.key)) return {...item, path: pathname}
            return item
        })
    }
    return state
}
const removeTabs = function(state: RouteConfig[] = [], action: reduxStoreTypes.RemoveTabAction) {
    const {
        tabIndex,
    } = action
    // const tabIndex = state.findIndex((route: RouteConfig) => route.path === removePath); // 要关闭的tab的位置
    if (!tabIndex || tabIndex < 1) {  // 首页tab不能被删除
        return state
    }
    return state.filter((tab: RouteConfig, index: number) => (index !== tabIndex))
}
const tabs = (state = [], action: reduxStoreTypes.TabAction | reduxStoreTypes.RemoveTabAction) => {
    switch(action.type) {
        case ADD_TAB:
            return addTabs(state, (action as reduxStoreTypes.TabAction))
        case DELETE_TAB:
            return removeTabs(state, (action as reduxStoreTypes.RemoveTabAction))
        default: 
            return state
    }
}
export default tabs